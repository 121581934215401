import Vue from 'vue'
import VueRouter from 'vue-router'
import LayoutMain from '../views/layout/Main.vue'
import auth from '../middleware/auth'
import i18n from "@/i18n";

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        component: LayoutMain,
        meta: {},
        children: [
            {
                path: '/',
                name: 'Notification',
                component: () => import('../views/pages/Notification.vue'),
                meta: {
                    permissions: [],
                    middleware: [auth],
                    pageName: i18n.t('menus.notification'),
                    title: i18n.t('menus.notification'),
                },
            },
            {
                path: '/dashboard',
                name: 'Dashboard',
                component: () => import('../views/pages/DashboardNew.vue'),
                meta: {
                    permissions: ['dashboard_manager'],
                    middleware: [auth],
                    pageName: i18n.t('menus.dashboard'),
                    title: i18n.t('menus.dashboard'),
                },
            },
            {
                path: '/dashboard-master',
                name: 'DashboardMaster',
                component: () => import('../views/pages/DashboardMaster.vue'),
                meta: {
                    permissions: ['warehouse_dashboard_master'],
                    middleware: [auth],
                    pageName: i18n.t('menus.dashboard_master'),
                    title: i18n.t('menus.dashboard_master'),
                },
            },
            {
                path: '/administration',
                name: 'Administration',
                component: () => import('../views/pages/administration/Index.vue'),
                meta: {
                    permissions: ['warehouse_admin_timekeeping', 'warehouse_admin_payroll', 'warehouse_admin_bill', 'warehouse_admin_package'],
                    middleware: [auth],
                    pageName: i18n.t('menus.administration'),
                    title: i18n.t('menus.administration'),
                },
            },
            {
                path: '/internal-request',
                name: 'InternalRequest',
                component: () => import('../views/pages/internal_requests/Index.vue'),
                meta: {
                    permissions: ['internal_request_manager'],
                    middleware: [auth],
                    pageName: i18n.t('menus.order_request'),
                    title: i18n.t('menus.order_request'),
                },
            },
            {
                path: '/goods/location-history',
                name: 'GoodsLocationHistory',
                component: () => import('../views/pages/goods/LocationHistory.vue'),
                meta: {
                    permissions: ['goods_location_history_manager'],
                    middleware: [auth],
                    pageName: i18n.t('menus.goods_location_history'),
                    title: i18n.t('menus.goods_location_history')
                },
            },
            {
                path: '/goods/list',
                name: 'GoodsList',
                component: () => import('../views/pages/goods/Index.vue'),
                meta: {
                    permissions: ['goods_report_manager'],
                    middleware: [auth],
                    pageName: i18n.t('menus.goods_manager'),
                    title: i18n.t('menus.goods_manager'),
                },
            },
            {
                path: '/goods/uid',
                name: 'GoodsUid',
                component: () => import('../views/pages/goods/Uid.vue'),
                meta: {
                    permissions: ['goods_uid_manager'],
                    middleware: [auth],
                    pageName: i18n.t('menus.goods_uid_manager'),
                    title: i18n.t('menus.goods_uid_manager'),
                },
            },
            {
                path: '/goods/uid-failed',
                name: 'GoodsUidFailed',
                component: () => import('../views/pages/goods/UidFailed.vue'),
                meta: {
                    permissions: ['goods_uid_failed'],
                    middleware: [auth],
                    pageName: i18n.t('menus.goods_uid_failed'),
                    title: i18n.t('menus.goods_uid_failed'),
                },
            },
            {
                path: '/goods/find',
                name: 'GoodsFind',
                component: () => import('../views/pages/goods/Find.vue'),
                meta: {
                    permissions: [],
                    middleware: [auth],
                    pageName: i18n.t('menus.goods_uid_find'),
                    title: i18n.t('menus.goods_uid_find'),
                },
            },
            {
                path: '/goods/find-manager',
                name: 'GoodsFindManager',
                component: () => import('../views/pages/goods/FindManager.vue'),
                meta: {
                    permissions: ['find_goods_manager'],
                    middleware: [auth],
                    pageName: i18n.t('menus.goods_uid_find_manager'),
                    title: i18n.t('menus.goods_uid_find_manager'),
                },
            },
            {
                path: '/goods/prepair',
                name: 'GoodsPrepair',
                component: () => import('../views/pages/goods/Prepair.vue'),
                meta: {
                    permissions: ['goods_prepair'],
                    middleware: [auth],
                    pageName: i18n.t('menus.goods_uid_prepair'),
                    title: i18n.t('menus.goods_uid_prepair'),
                },
            },
            {
                path: '/goods/qc',
                name: 'GoodsQC',
                component: () => import('../views/pages/goods/QC.vue'),
                meta: {
                    permissions: ['goods_qc'],
                    middleware: [auth],
                    pageName: i18n.t('menus.goods_qc'),
                    title: i18n.t('menus.goods_qc'),
                },
            },
            {
                path: '/goods/export-defective',
                name: 'GoodsExportDefective',
                component: () => import('../views/pages/goods/ExportDefective.vue'),
                meta: {
                    permissions: ['goods_export_defective'],
                    middleware: [auth],
                    pageName: i18n.t("menus.goods_error_export"),
                    title: i18n.t("menus.goods_error_export"),
                },
            },
            {
                path: '/locations/set',
                name: 'LocationSet',
                component: () => import('../views/pages/locations/Set.vue'),
                meta: {
                    permissions: ['location_set_manager'],
                    middleware: [auth],
                    pageName: i18n.t('menus.location_set'),
                    title: i18n.t('menus.location_set'),
                },
            },
            {
                path: '/locations/change',
                name: 'LocationChange',
                component: () => import('../views/pages/locations/Change.vue'),
                meta: {
                    permissions: ['location_change_manager'],
                    middleware: [auth],
                    pageName: i18n.t('menus.location_change'),
                    title: i18n.t('menus.location_change'),
                },
            },
            {
                path: '/locations/roll',
                name: 'LocationRoll',
                component: () => import('../views/pages/locations/Roll.vue'),
                meta: {
                    permissions: ['location_roll_manager'],
                    middleware: [auth],
                    pageName: i18n.t('menus.location_roll'),
                    title: i18n.t('menus.location_roll'),
                },
            },
            {
                path: '/locations/warehouse',
                name: 'WarehouseLocation',
                component: () => import('../views/pages/locations/Warehouse.vue'),
                meta: {
                    permissions: ['location_warehouse'],
                    middleware: [auth],
                    pageName: i18n.t('menus.location_warehouse'),
                    title: i18n.t('menus.location_warehouse'),
                },
            },
            {
                path: '/goods-return/list',
                name: 'GoodsReturn',
                component: () => import('../views/pages/goods_return/Index.vue'),
                meta: {
                    permissions: ['goods_return_manager'],
                    middleware: [auth],
                    pageName: i18n.t('menus.goods_return_manager'),
                    title: i18n.t('menus.goods_return_manager'),
                },
            },
            {
                path: '/goods-return/receive',
                name: 'GoodsReturnReceive',
                component: () => import('../views/pages/goods_return/Receive.vue'),
                meta: {
                    permissions: ['goods_return_receive_manager'],
                    middleware: [auth],
                    pageName: i18n.t('menus.goods_return_receive'),
                    title: i18n.t('menus.goods_return_receive'),
                    moduleWorkingName: 'return_receive',
                },
            },
            {
                path: '/goods-return/import',
                name: 'GoodsReturnImport',
                component: () => import('../views/pages/goods_return/Import.vue'),
                meta: {
                    permissions: ['goods_return_import_manager'],
                    middleware: [auth],
                    pageName: i18n.t('menus.goods_return_import'),
                    title: i18n.t('menus.goods_return_import'),
                    moduleWorkingName: 'return_import',
                },
            },
            {
                path: '/goods-return/stowing',
                name: 'GoodsReturnStowing',
                component: () => import('../views/pages/goods_return/Stowing.vue'),
                meta: {
                    permissions: ['goods_return_stowing_manager'],
                    middleware: [auth],
                    pageName: i18n.t('menus.goods_return_stowing'),
                    title: i18n.t('menus.goods_return_stowing'),
                    moduleWorkingName: 'return_stowing',
                },
            },
            {
                path: '/goods-receipt/list',
                name: 'GoodsReceipt',
                component: () => import('../views/pages/goods_receipt/Index.vue'),
                meta: {
                    permissions: ['goods_receipt_manager'],
                    middleware: [auth],
                    pageName: i18n.t('menus.goods_receipt_manager'),
                    title: i18n.t('menus.goods_receipt_manager'),
                },
            },
            {
                path: '/goods-receipt/waiting',
                name: 'GoodsReceiptWaiting',
                component: () => import('../views/pages/goods_receipt/Waiting.vue'),
                meta: {
                    permissions: ['goods_receipt_waiting'],
                    middleware: [auth],
                    pageName: i18n.t('menus.goods_receipt_waiting_s1'),
                    title: i18n.t('menus.goods_receipt_waiting_s1'),
                    moduleWorkingName: 'receipt',
                },
            },
            {
                path: '/goods-receipt/fast',
                name: 'GoodsReceiptFast',
                component: () => import('../views/pages/goods_receipt/Fast.vue'),
                meta: {
                    permissions: ['goods_receipt_fast'],
                    middleware: [auth],
                    pageName: i18n.t('menus.goods_receipt_waiting_s2'),
                    title: i18n.t('menus.goods_receipt_waiting_s2'),
                    moduleWorkingName: 'receipt',
                },
            },
            {
                path: '/goods-receipt/one',
                name: 'GoodsReceiptOne',
                component: () => import('../views/pages/goods_receipt/One.vue'),
                meta: {
                    permissions: ['goods_receipt_one'],
                    middleware: [auth],
                    pageName: i18n.t('menus.goods_receipt_waiting_s4'),
                    title: i18n.t('menus.goods_receipt_waiting_s4'),
                    moduleWorkingName: 'receipt',
                },
            },
            {
                path: '/goods-receipt/uid',
                name: 'GoodsReceiptHasStamp',
                component: () => import('../views/pages/goods_receipt/HasStamp.vue'),
                meta: {
                    permissions: ['goods_receipt_has_stamp'],
                    middleware: [auth],
                    pageName: i18n.t('menus.goods_receipt_waiting_s3'),
                    title: i18n.t('menus.goods_receipt_waiting_s3'),
                    moduleWorkingName: 'receipt',
                },
            },
            {
                path: '/goods-receipt/record',
                name: 'GoodsReceiptRecord',
                component: () => import('../views/pages/goods_receipt/Record.vue'),
                meta: {
                    permissions: ['goods_receipt_record'],
                    middleware: [auth],
                    pageName: i18n.t('menus.goods_receipt_record'),
                    title: i18n.t('menus.goods_receipt_record'),
                },
            },
            {
                path: '/goods-receipt/stowing',
                name: 'GoodsReceiptStowing',
                component: () => import('../views/pages/goods_receipt/Stowing.vue'),
                meta: {
                    permissions: ['goods_receipt_stowing'],
                    middleware: [auth],
                    pageName: i18n.t('menus.goods_receipt_stowing'),
                    title: i18n.t('menus.goods_receipt_stowing'),
                    moduleWorkingName: 'receipt_stowing',
                },
            },
            {
                path: '/goods-receipt/wrong-return',
                name: 'GoodsReceiptWrongReturn',
                component: () => import('../views/pages/goods_receipt/WrongReturn.vue'),
                meta: {
                    permissions: ['goods_receipt_wrong_return'],
                    middleware: [auth],
                    pageName: i18n.t('menus.receipt_wrong_return'),
                    title: i18n.t('menus.receipt_wrong_return'),
                },
            },
            {
                path: '/goods-issue/list',
                name: 'GoodsIssue',
                component: () => import('../views/pages/goods_issue/Index.vue'),
                meta: {
                    permissions: ['goods_issue_manager'],
                    middleware: [auth],
                    pageName: i18n.t('menus.goods_issue_manager'),
                    title: i18n.t('menus.goods_issue_manager'),
                },
            },
            {
                path: '/goods-issue/detail',
                name: 'GoodsIssueDetail',
                component: () => import('../views/pages/goods_issue/Detail.vue'),
                meta: {
                    permissions: ['order_detail_manager'],
                    middleware: [auth],
                    pageName: i18n.t('menus.goods_issue_detail'),
                    title: i18n.t('menus.goods_issue_detail'),
                },
            },
            {
                path: '/goods-issue/employee-export',
                name: 'GoodsIssueEmployeeExport',
                component: () => import('../views/pages/goods_issue/EmployeeExport.vue'),
                meta: {
                    permissions: ['employee_export_order'],
                    middleware: [auth],
                    pageName: i18n.t('labels.employee_export_order'),
                    title: i18n.t('labels.employee_export_order'),
                },
            },
            {
                path: '/goods-issue/pickup',
                name: 'GoodsIssuePickup',
                component: () => import('../views/pages/goods_issue/Pickup.vue'),
                meta: {
                    permissions: ['goods_issue_pickup'],
                    middleware: [auth],
                    pageName: i18n.t('menus.goods_issue_pickup'),
                    title: i18n.t('menus.goods_issue_pickup'),
                },
            },
            {
                path: '/goods-issue/pickup-history',
                name: 'GoodsIssuePickupHistory',
                component: () => import('../views/pages/goods_issue/PickupHistory.vue'),
                meta: {
                    permissions: ['goods_issue_pickup_history'],
                    middleware: [auth],
                    pageName: i18n.t('menus.goods_issue_pickup_history'),
                    title: i18n.t('menus.goods_issue_pickup_history'),
                },
            },
            {
                path: '/goods-issue/packing',
                name: 'GoodsIssuePacking',
                component: () => import('../views/pages/goods_issue/Packing.vue'),
                meta: {
                    permissions: ['goods_issue_packing'],
                    middleware: [auth],
                    pageName: i18n.t('menus.goods_issue_packing'),
                    title: i18n.t('menus.goods_issue_packing'),
                    moduleWorkingName: 'packing',
                },
            },
            {
                path: '/goods-issue/handover',
                name: 'GoodsIssueHandover',
                component: () => import('../views/pages/goods_issue/Handover.vue'),
                meta: {
                    permissions: ['goods_issue_handover'],
                    middleware: [auth],
                    pageName: i18n.t('menus.goods_issue_handover'),
                    title: i18n.t('menus.goods_issue_handover'),
                    moduleWorkingName: 'handover',
                },
            },
            {
                path: '/employees',
                name: 'Employee',
                component: () => import('../views/pages/employees/Index.vue'),
                meta: {
                    permissions: ['employee_manager'],
                    middleware: [auth],
                    pageName: i18n.t('menus.employee_manager'),
                    title: i18n.t('menus.employee_manager'),
                },
            },
            {
                path: '/timekeeping',
                name: 'Timekeeping',
                component: () => import('../views/pages/timekeeping/Index.vue'),
                meta: {
                    permissions: [],
                    middleware: [auth],
                    pageName: i18n.t('menus.timekeeping'),
                    title: i18n.t('menus.timekeeping'),
                },
            },
            {
                path: '/overtime-timekeeping',
                name: 'OvertimeTimekeeping',
                component: () => import('../views/pages/timekeeping/Overtime.vue'),
                meta: {
                    permissions: [],
                    middleware: [auth],
                    pageName: i18n.t('menus.overtime_timekeeping'),
                    title: i18n.t('menus.overtime_timekeeping'),
                },
            },
            {
                path: '/goods/print-stamp',
                name: 'GoodsPrintStamp',
                component: () => import('../views/pages/goods/PrintStamp.vue'),
                meta: {
                    permissions: ['download_goods_stamp'],
                    middleware: [auth],
                    pageName: i18n.t('menus.download_goods_stamp'),
                    title: i18n.t('menus.download_goods_stamp'),
                },
            },
            {
                path: '/goods/re-print-stamp',
                name: 'GoodsRePrintStamp',
                component: () => import('../views/pages/goods/RePrintStamp.vue'),
                meta: {
                    permissions: ['goods_generate_stamp_again'],
                    middleware: [auth],
                    pageName: i18n.t('menus.re_print_stamp'),
                    title: i18n.t('menus.re_print_stamp'),
                },
            },
            {
                path: '/order-request',
                name: 'OrderRequest',
                component: () => import('../views/pages/order_request/Index.vue'),
                meta: {
                    permissions: ['order_request'],
                    middleware: [auth],
                    pageName: i18n.t('menus.order_request'),
                    title: i18n.t('menus.order_request'),
                },
            },
            {
                path: '/packaging',
                name: 'Packaging',
                component: () => import('../views/pages/packaging/Index.vue'),
                meta: {
                    permissions: ['packaging_manager'],
                    middleware: [auth],
                    pageName: i18n.t('menus.packaging'),
                    title: i18n.t('menus.packaging'),
                },
            },
            {
                path: '/check-basket',
                name: 'CheckBasket',
                component: () => import('../views/pages/test/CheckBasket.vue'),
                meta: {
                    permissions: ['check_basket'],
                    middleware: [auth],
                    pageName: i18n.t('menus.check_basket'),
                    title: i18n.t('menus.check_basket'),
                },
            },
            {
                path: '/find-pickup-basket',
                name: 'FindPickupBasket',
                component: () => import('../views/pages/test/FindPickupBasket.vue'),
                meta: {
                    permissions: ['find_pickup_basket'],
                    middleware: [auth],
                    pageName: i18n.t('menus.find_pickup_basket'),
                    title: i18n.t('menus.find_pickup_basket'),
                },
            },
            {
                path: '/inventory',
                name: 'Inventory',
                component: () => import('../views/pages/inventory/Index.vue'),
                meta: {
                    permissions: ['inventory'],
                    middleware: [auth],
                    pageName: i18n.t('menus.inventory'),
                    title: i18n.t('menus.inventory'),
                },
            },
            {
                path: '/day-off',
                name: 'DayOff',
                component: () => import('../views/pages/administration/DayOff'),
                meta: {
                    permissions: ['admin_day_off'],
                    middleware: [auth],
                    pageName: i18n.t('menus.day_off'),
                    title: i18n.t('menus.day_off'),
                },
            },
        ]
    },
    {
      path: '/v2*',
      redirect: '/'
    },
    {
        path: '/login',
        name: 'Login',
        component: () => import('../views/pages/Login.vue'),
    },
    {
        path: '/test-qr',
        name: 'TestQrScan',
        component: () => import('../views/pages/test/QrScan.vue'),
    },
    {
        path: '/register',
        name: 'Register',
        component: () => import('../views/pages/Register.vue'),
    },
    {
        path: '/403',
        name: 'Error403',
        component: () => import('../views/pages/errors/403.vue'),
    }
]


const router = new VueRouter({
    mode: 'history',
    base: '/',
    routes
})

function nextFactory(context, middleware, index) {
    const subsequentMiddleware = middleware[index];
    if (!subsequentMiddleware) return context.next;

    return (...parameters) => {
        context.next(...parameters);
        const nextMiddleware = nextFactory(context, middleware, index + 1);
        subsequentMiddleware({ ...context, next: nextMiddleware });
    };
}

router.beforeEach((to, from, next) => {
    if (to.meta && to.meta.middleware) {
        const middleware = Array.isArray(to.meta.middleware) ? to.meta.middleware : [to.meta.middleware];
        const context = {
            from,
            next,
            router,
            to,
        };
        const nextMiddleware = nextFactory(context, middleware, 1);
        return middleware[0]({ ...context, next: nextMiddleware });
    }

    return next();
});

router.afterEach((to) => {
    Vue.nextTick(() => {
        document.title = to.meta.title || process.env.VUE_APP_TITLE;
    });
});

export default router
