import { httpClient } from "@/libs/http";
import { checkUserLoginPermission } from "@/libs/helpers";

export default async function auth({ next, router, to }) {
    const st = localStorage.getItem('_st')
    if (!st) {
        return router.push({ name: 'Login' });
    }
    try {
        if (!window.me) {
            const { data } = await httpClient.post('/me/v1')
            window.me = { ...data }
        }
        const userPermission = window.me && window.me.permissions || []
        const check = checkUserLoginPermission(userPermission, to.meta.permissions)
        if (check) {
            return next();
        }
        return next('/403');
    } catch (e) {
        console.error('getMe->ERROR:', e)
        return next('/403');
    }
}
