import {checkUserLoginPermission, isMobile} from "@/libs/helpers";
import {httpClient} from "@/libs/http";

export default {
    methods: {
        checkPermission(permissions) {
            const userPermission = window.me && window.me.permissions || []
            return checkUserLoginPermission(userPermission, permissions)
        },
        checkMenuMobile(isCheck) {
            return !isCheck || (isCheck && isMobile())
        },
        checkMenuDesktop(isCheck) {
            return !isCheck || (isCheck && !isMobile())
        },
        updateWorking({type, item}) {
            window.me.working[type] = 1
            window.me.working[`${type}_item`] = item
            httpClient.post('/me/v1/update-working', {type, item})
        },
    }
}
