import axios from 'axios'
import httpBuilder from './httpBuilder.js'
import { eventHub } from "@/libs/eventhub";

const baseClient = axios.create({
    baseURL: process.env.VUE_APP_API_V2_URL
});

let defaultLang = localStorage.getItem('_lang') || 'vi'

baseClient.interceptors.request.use(
    function (config) {
        const st = localStorage.getItem('_st')
        const wh = localStorage.getItem('_wh') || 0
        config.headers["Authorization"] = `Bearer ${st}`
        config.headers["swh"] = wh
        config.headers["skey"] = `${process.env.VUE_APP_SSC_KEY}`
        config.headers["accept-language"] = defaultLang
        eventHub.$emit('isLoading')
        return config;
    },
    function (error) {
        eventHub.$emit('isLoading')
        return Promise.reject(error);
    }
);

baseClient.interceptors.response.use(
    response => {
        eventHub.$root.$emit('endLoading')
        return response;
    },
    error => {
        eventHub.$root.$emit('endLoading')
        return Promise.reject(error);
    }
);

export const httpClient = httpBuilder(baseClient)
