<template>
  <div>
    <v-text-field
      v-model="cmpValue"
      v-mask="currencyMask"
      class="c-input-small"
      :label="label"
      :placeholder="label"
      :autofocus="autofocus"
      :dense="dense"
      :single-line="singleLine"
      :outlined="outlined"
      :clearable="clearable"
      hide-details
    ></v-text-field>
  </div>
</template>

<script>
import createNumberMask from "text-mask-addons/dist/createNumberMask";
const currencyMask = createNumberMask({
  prefix: "",
  allowDecimal: true,
  includeThousandsSeparator: true,
  allowNegative: false,
});
export default {
  name: "InputNumber",
  model: { prop: "value", event: "input" },
  props: {
    value: {
      // type: String,
      type: [String, Number],
      default: "0",
    },
    label: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: undefined,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    dense: {
      type: Boolean,
      default: false,
    },
    autofocus: {
      type: Boolean,
      default: false,
    },
    error: {
      type: Boolean,
      default: false,
    },
    singleLine: {
      type: Boolean,
      default: false,
    },
    hideDetails: {
      type: [Boolean, String],
      default: false,
    },
    errorMessages: {
      type: [Array, String],
      default: () => [],
    },
    rules: {
      type: [Array, String],
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    outlined: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    currencyMask,
  }),
  computed: {
    cmpValue: {
      get: function () {
        return this.value;
      },
      set: function (newValue) {
        newValue = this.cleanNumber(newValue);
        if (newValue != this.value) {
          this.$emit("input", newValue);
        }
      },
    },
  },
  methods: {
    cleanNumber(value) {
      if (!value) {
        return value;
      }
      return +value.replaceAll(",", "");
    },
  },
};
</script>
