<template>
  <v-autocomplete
    ref="scbwInput"
    class="c-input-small"
    :value="value"
    :items="options"
    :label="$t('labels.customer')"
    :disabled="disabled"
    :class="customClass"
    :outlined="outlined"
    :dense="dense"
    :hide-details="hideDetails"
    :single-line="singleLine"
    @input="updateValue"
  ></v-autocomplete>
</template>

<script>
import { httpClient } from "@/libs/http";
export default {
  name: "SelectCustomerByWarehouse",
  props: {
    value: {
      type: [Number, String, Array, Object],
      default: () => null,
    },
    label: {
      type: String,
      default: () => "Kho",
    },
    disabled: {
      type: Boolean,
      default: () => false,
    },
    outlined: {
      type: Boolean,
      default: () => true,
    },
    dense: {
      type: Boolean,
      default: () => true,
    },
    hideDetails: {
      type: Boolean,
      default: () => true,
    },
    singleLine: {
      type: Boolean,
      default: () => true,
    },
    customClass: {
      type: String,
      default: () => null,
    },
    idWarehouse: {
      type: [String, Number],
      default: () => null,
    },
    isActiveOnly: {
      type: [Boolean, Number],
      default: () => true,
    },
  },
  data: () => ({
    options: [],
  }),
  model: {
    prop: ["value"],
    event: "change",
  },
  computed: {},
  watch: {
    idWarehouse() {
      this.getList();
    },
  },
  mounted() {},
  methods: {
    focusInput() {
      this.$refs.scbwInput.focus();
    },
    updateValue(val) {
      this.$emit("change", val);
    },
    async getList() {
      this.options = [];
      if (!this.idWarehouse) {
        this.updateValue(null);
        return false;
      }

      const { data } = await httpClient.post(
        "/customer/v1/get-all-has-contract",
        {
          id_warehouse: this.idWarehouse,
          is_active: this.isActiveOnly,
        }
      );
      const options = data.map((e) => ({
        value: e.id,
        text: e.company_name || "",
      }));
      this.options = [...options];
      if (options.length === 1) {
        this.updateValue(options[0].value);
      }
    },
  },
};
</script>

<style scoped></style>
