var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-simple-table',{staticClass:"table-padding-2",attrs:{"fixed-header":"","height":_vm.tableHeight},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',{staticClass:"v-data-table-header"},[_c('tr',_vm._l((_vm.columns),function(column,key){return (_vm.isColumnShow(column))?_c('th',{key:`${key}_${column.name}`,staticClass:"text-center",attrs:{"role":"columnheader"}},[(column.type === '')?_c('div',{class:column.class},[_vm._v(" "+_vm._s(column.label)+" ")]):(column.type === 'button')?_c('div',{class:column.class},[(column.headerAction)?_c('v-btn',{attrs:{"color":column.headerActionColor,"small":""},on:{"click":function($event){return _vm.emitAction(column.headerAction, {})}}},[_vm._v(" "+_vm._s(column.headerActionLabel)+" ")]):_vm._e()],1):_c(column.type,_vm._b({tag:"component",class:column.class,attrs:{"label":column.label,"placeholder":column.placeholder,"name":column.name,"sort-name":column.sortName,"options":column.options,"has-sort":column.hasSort,"allow-null":column.allowNull,"filters":_vm.filters,"default-value":column.defaultValue,"allow-paste":column.allowPaste || false,"sorting":_vm.filters.sort_by},on:{"onFilter":_vm.onFilterChange,"onSort":_vm.onSortChange}},'component',column.params,false))],1):_vm._e()}),0)]),_c('tbody',_vm._l((_vm.items),function(item,index){return _c('tr',{key:`i_${index}_${item.id}`,staticClass:"text-center"},_vm._l((_vm.columns),function(column,key){return (_vm.isColumnShow(column))?_c('td',{key:`v_${key}_${column.key}`},[(column.component === 'DeliveryTracking')?_c('div',[_c('DeliveryTracking',{attrs:{"order":item}})],1):(column.component === 'OrderTracking')?_c('div',[_c('OrderTracking',{attrs:{"tracking-id":item[column.key]}})],1):(column.component === 'Image')?_c('div',[(item[column.key])?_c('ImageViewer',{attrs:{"url":item[column.key]}}):(
                  column.action && _vm.checkPermission(column.actionPermissions)
                )?_c('span',{staticClass:"text-decoration-underline info--text cursor-pointer",on:{"click":function($event){return _vm.emitAction(column.action, item)}}},[_vm._v(" "+_vm._s(column.actionText)+" ")]):_vm._e()],1):(['date-range-filter'].includes(column.type))?_c('div',[_vm._v(" "+_vm._s(item[column.key] ? column.dateOnly ? _vm.formatDateTime(item[column.key], "DD/MM/YYYY") : _vm.formatDateTime(item[column.key]) : "")+" ")]):(['input-filter-from-to'].includes(column.type))?_c('div',[(
                  column.action && _vm.checkPermission(column.actionPermissions)
                )?_c('span',{staticClass:"text-decoration-underline info--text cursor-pointer",on:{"click":function($event){return _vm.emitAction(column.action, item)}}},[_vm._v(" "+_vm._s(item[column.key] || item[column.key] === 0 ? _vm.formatNumber(item[column.key]) : "")+" ")]):_c('span',[_vm._v(" "+_vm._s(item[column.key] || item[column.key] === 0 ? _vm.formatNumber(item[column.key]) : "")+" ")])]):(['button'].includes(column.type) && column.action)?_c('div',[_c('v-btn',{attrs:{"color":column.actionColor,"x-small":""},on:{"click":function($event){return _vm.emitAction(column.action, item)}}},[_vm._v(" "+_vm._s(column.actionLabel)+" ")])],1):(['select-filter'].includes(column.type))?_c('div',[_vm._v(" "+_vm._s(_vm.getTxtFromOption( column.options, item[column.key], column.hiddenZero ))+" ")]):_c('div',[(
                  column.action && _vm.checkPermission(column.actionPermissions)
                )?_c('div',{staticClass:"text-decoration-underline info--text cursor-pointer",domProps:{"innerHTML":_vm._s(item[column.key])},on:{"click":function($event){return _vm.emitAction(column.action, item)}}}):_c('div',{domProps:{"innerHTML":_vm._s(item[column.key])}})]),(column.buttonActions && column.buttonActions.length > 0)?_vm._l((column.buttonActions),function(buttonAction){return _c('div',[(!buttonAction.allowKey || item[buttonAction.allowKey])?_c('v-btn',{class:buttonAction.class,attrs:{"x-small":"","color":buttonAction.color},on:{"click":function($event){return _vm.emitAction(buttonAction.action, item)}}},[_vm._v(" "+_vm._s(buttonAction.text)+" ")]):_vm._e()],1)}):_vm._e()],2):_vm._e()}),0)}),0)]},proxy:true}])}),_c('v-row',{staticClass:"pt-3"},[_c('v-col',{attrs:{"cols":"12","sm":"3"}},[_c('div',{staticClass:"d-flex justify-start align-center",staticStyle:{"min-height":"40px"}},[(_vm.apiDownloadUrl)?_c('v-btn',{staticClass:"mr-2",attrs:{"color":"info"},on:{"click":_vm.downloadExcel}},[_c('v-icon',[_vm._v("mdi-download")]),_vm._v(" "+_vm._s(_vm.$t("labels.excel"))+" ")],1):_vm._e(),(_vm.sums && _vm.sums.length > 0)?_c('div',{staticClass:"d-flex flex-wrap table-footer-count"},_vm._l((_vm.sums),function(sItem){return _c('div',{staticClass:"mr-2"},[_vm._v(" "+_vm._s(sItem.text)+": "),_c('b',[_vm._v(_vm._s(_vm.formatNumber(_vm.sum[sItem.key])))])])}),0):_vm._e(),(_vm.countComponent)?_c(_vm.countComponent,{tag:"component",attrs:{"counts":_vm.counts}}):_vm._e()],1)]),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-pagination',{attrs:{"length":_vm.totalPage,"total-visible":6},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"3"}},[_c('div',{staticClass:"d-flex align-center justify-end",staticStyle:{"min-height":"40px"}},[_c('div',[_vm._l((_vm.footerActions),function(footerAction){return [(
                footerAction.action &&
                _vm.checkPermission(footerAction.permissions)
              )?_c('v-btn',{class:footerAction.class,attrs:{"color":footerAction.color},on:{"click":function($event){return _vm.emitAction(footerAction.action, _vm.filters)}}},[_vm._v(" "+_vm._s(footerAction.text)+" ")]):(
                footerAction.link && _vm.checkPermission(footerAction.permissions)
              )?_c('a',{class:footerAction.class,attrs:{"href":footerAction.link}},[_vm._v(" "+_vm._s(footerAction.text)+" ")]):_vm._e()]})],2),(_vm.isPageShow)?_c('div',[_c('v-text-field',{staticClass:"c-input-xs mr-2",staticStyle:{"width":"55px"},attrs:{"dense":"","outlined":"","hide-details":"","label":_vm.$t('labels.page'),"placeholder":_vm.$t('labels.page')},model:{value:(_vm.page),callback:function ($$v) {_vm.page=_vm._n($$v)},expression:"page"}})],1):_vm._e(),_c('div',[(_vm.viewModes && _vm.viewModes.length > 0)?_c('v-autocomplete',{staticClass:"c-input-small",attrs:{"items":_vm.viewModes,"outlined":"","dense":"","hide-details":"","label":_vm.$t('labels.view_mode')},on:{"change":_vm.onModeChange},model:{value:(_vm.viewMode),callback:function ($$v) {_vm.viewMode=$$v},expression:"viewMode"}}):_vm._e()],1),(_vm.customColumns.length > 0)?_c('div',{staticClass:"pl-2"},[_c('v-menu',{attrs:{"top":"","offset-y":"","close-on-content-click":false,"min-width":"200px"},on:{"input":_vm.onMenuToggle},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({staticClass:"cursor-pointer",attrs:{"color":"primary"}},'v-icon',attrs,false),on),[_vm._v("mdi-dots-grid")])]}}],null,false,3378085472)},[_c('v-list',{attrs:{"dense":""}},[_c('v-list-item',[_vm._v(_vm._s(_vm.$t("labels.display")))]),_vm._l((_vm.customColumns),function(item,index){return _c('v-list-item',{key:`c_${index}_${item.name}`,staticStyle:{"min-height":"30px"}},[_c('v-list-item-title',{staticClass:"cursor-pointer font-weight-regular",on:{"click":function($event){return _vm.toggleCustomeColumn(item)}}},[_c('div',{staticClass:"d-flex align-center"},[(_vm.selectedCustomColumns.includes(item.key))?_c('v-icon',{staticClass:"primary--text",attrs:{"small":""}},[_vm._v("mdi-checkbox-marked")]):_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-checkbox-blank-outline")]),_c('span',{staticClass:"ml-2"},[_vm._v(_vm._s(item.label))])],1)])],1)})],2)],1)],1):_vm._e()])])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }